<template>
    <page-container :title="breadcrumb" :breadcrumb="breadcrumb">
      <div class="bg-white">
        <div class="main-container" style="margin-top: -15px">
          <div class="title">FRP端口管理</div>
        </div>
      </div>
      <div class="container">
        <div class="table-btns" style="margin-top: 2%">
          <a-button type="primary" size="large" @click="getOrSet" style="margin-left: 80%">
            {{status ?'下发当前参数':'获取最新参数' }}</a-button>
          <a-icon type="redo" class="after-btn table-refresh" @click="getParamsConfig" style="margin-left: 1%;font-size: 20px"></a-icon>
        </div>
        <a-form-model ref="modalForm" layout="inline" class="form-in-twocolumns"  :label-col="{span:9}" :wrapper-col="{span:14}" style="margin-top: 20px">
          <a-form-model-item label="设备编码" prop="devicenum">
            <a-input v-model="devicenum" placeholder="请输入设备编码"></a-input>
          </a-form-model-item>
        </a-form-model>
        <a-form-model ref="modalForm" layout="inline" class="form-in-twocolumns"  :label-col="{span:9}" :wrapper-col="{span:14}" style="margin-top: 1%">
          <a-form-model-item label="节点1名" prop="title_1">
            <a-input v-model="frpConfigs.title_1" placeholder=""></a-input>
          </a-form-model-item>
          <a-form-model-item label="FPR服务器地址" prop="server_addr">
            <a-input v-model="frpConfigs.server_addr" placeholder=""></a-input>
          </a-form-model-item>
          <a-form-model-item label="FPR服务器注册端口" prop="server_port ">
            <a-input v-model="frpConfigs.server_port" placeholder=""></a-input>
          </a-form-model-item>
          <a-form-model-item label="FPR服务器token" prop="token ">
            <a-input v-model="frpConfigs.token" placeholder=""></a-input>
          </a-form-model-item>
        </a-form-model>
        <a-form-model ref="modalForm" layout="inline" class="form-in-twocolumns"  :label-col="{span:9}" :wrapper-col="{span:14}" style="margin-top: 1%">
          <a-form-model-item label="节点2名" prop="title_2">
            <a-input v-model="frpConfigs.title_2" placeholder=""></a-input>
          </a-form-model-item>
          <a-form-model-item label="协议类型" prop="type">
            <a-input v-model="frpConfigs.type" placeholder=""></a-input>
          </a-form-model-item>
          <a-form-model-item label="远控客户端本地地址" prop="local_ip">
            <a-input v-model="frpConfigs.local_ip" placeholder=""></a-input>
          </a-form-model-item>
          <a-form-model-item label="远控客户端本地端口" prop="local_port">
            <a-input v-model="frpConfigs.local_port" placeholder=""></a-input>
          </a-form-model-item>
          <a-form-model-item label="远控客户端转发端口" prop="remote_port">
            <a-input v-model="frpConfigs.remote_port" placeholder=""></a-input>
          </a-form-model-item>
        </a-form-model>
      </div>
    </page-container>
</template>
<script>
import {
  getDeviceparamValueList,
  setDeviceparamValueList,
} from "A/ai";
export default {
  name: "FRPManagement",
  data() {
    return {
      breadcrumb: [
        {
          name: '系统配置',
          path: ''
        },
        {
          name: '高级设置',
          path: ''
        },
        {
          name: 'FRP端口管理',
          path: ''
        },
      ],
      frpConfigs:{
        title_1:'',
        server_addr:'',
        server_port:'',
        token:'',
        title_2:'',
        type:'',
        local_ip:'',
        local_port:'',
        remote_port:'',
      },
      devicenum: '',
      status:false,
    }
  },
  watch: {
  },
  created() {
  },
  methods: {
    getParamsConfig() {
      if(this.devicenum){
        this.status=true
        let params = {
          devicenum: this.devicenum,
          data: [
            {
              paramstype: 2,
              params: {
                methord: 'GET',
                url: '/remote_frp',
              }
            }
          ]
        };
        getDeviceparamValueList(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            this.frpConfigs=res.item[0]
          }
        }).catch(err => {
          this.hideLoading();
        })
      }else{
       this.$message.error("请输入设备编码以查询")
      }
    },
    setParamsConfig(){
      if(this.devicenum){
        this.status=false;
        let params = {
          devicenum: this.devicenum,
          data: [
            {
              paramstype: 2,
              params: {
                methord: 'PUT',
                url:'/remote_frp',
                body: this.frpConfigs,
              }
            }
          ]
        };
        this.showLoading();
        setDeviceparamValueList(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            this.$message.success('操作成功');
          }else {
            this.$message.error(res.errormsg||'操作失败');
          }
        }).catch(err => {
          this.hideLoading();
          this.$message.error('操作失败');
        })
      }
    },
    getOrSet(){
      if(this.status){
        if(this.frpConfigs.title_1.length>0&&this.frpConfigs.server_addr.length>0&&this.frpConfigs.server_port.length>0&&this.frpConfigs.token.length>0
            &&this.frpConfigs.type.length>0&&this.frpConfigs.title_2.length>0&&this.frpConfigs.local_ip.length>0&&this.frpConfigs.local_port.length>0&&this.frpConfigs.remote_port.length>0){
          this.setParamsConfig();
        }else{
          this.$message.error("请确认参数填写完整")
        }
      }else{
        this.getParamsConfig();
      }
    },
  }
}
</script>
<style scoped>
.bg-white {
  background-color: #fff;
}
.container{
  background-color: #fff;
  margin-left: 1%;
  margin-right: 1%;
  margin-top: 1%;
  flex-grow: 1;
  overflow: auto;
  height: 730px;
}
.title {
  font-weight: bold;
  font-size: 20px;
  padding: 5px 20px;
}
.main-container {
  display: flex;
  padding: 10px;
}

</style>